const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
    {
    path: ["/doctordirectory"],
    exact: true,
    component: "DoctorDirectory/index",
  },
];

export default routes;
